import React from "react"
import Layout from "../components/layout"
import DesktopTemperaturasDelValle  from "../components/clima/desktop/temperaturasdelvalle";
import MobileTemperaturaDelValle from "../components/clima/mobile/TemperaturaDelValle";
import DesktopCiclosVegetativos from "../components/clima/desktop/ciclosvegetativos";
import MobileCiclosVegetativos from '../components/clima/mobile/Ciclosvegetativos';
import { BrowserView, MobileView } from 'react-device-detect';
import DesktopVideoHeader from "../components/clima/desktop/VideoHeader";
import MobileVideoHeader from "../components/clima/mobile/VideoHeader";
import SimpleLocalize from "../components/SimpleLocalize";
import DesktopSection2 from '../components/clima/desktop/Section2';
import MobileSection2 from '../components/clima/mobile/Section2';
import DesktopHeliofania from "../components/clima/desktop/heliofania";
import MobileHeliofania from "../components/clima/mobile/Heliofania";


const IndexPage = (props) => {

	return <Layout {...props}>
		<SimpleLocalize {...props}>
				<BrowserView>
					<DesktopVideoHeader />
					<DesktopSection2 />
					<DesktopHeliofania {...props} />
					<DesktopTemperaturasDelValle {...props} />
					<DesktopCiclosVegetativos {...props} />
				</BrowserView>
				<MobileView>
					<MobileVideoHeader />
					<MobileSection2 />
					<MobileHeliofania {...props} />
					<MobileTemperaturaDelValle {...props} />
					<MobileCiclosVegetativos {...props} />
				</MobileView>


		</SimpleLocalize>
	</Layout>
}

export default IndexPage